import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const WorkDone = ({ title, subTitle, data, titleFull, page, starGrid }) => {
  const [mapData, setMapData] = useState([...data]);
  const {t} = useTranslation();
  return (
    <>
      <div className="my-20 space-y-2 md:space-y-8">
        {
          title &&
          <div className={`text-3xl md:text-5xl font-semibold w-full  text-center mx-auto ${titleFull?"w-full":"md:w-5/6"}`}>
            {title}
          </div>
        }
        {
          subTitle &&
          <div className={`font-medium text-sm md:text-xl  text-center mx-auto w-10/12 md:w-full text-gray-500`}>
            {subTitle}
          </div>
        }
      </div>
      <div className={`font-sans flex flex-col mx-auto w-full space-y-10 md:space-y-20 `}>
        {
          mapData.map((map, index) => {
            return (
              <div
                className={
                  index % 2 === 0
                    ? `flex flex-col md:flex-row-reverse justify-between items-center `
                    : `flex flex-col md:flex-row justify-between items-center relative`
                }
              >
                {
                  index % 2 === 1 && starGrid &&
                  <>
                    <img
                      src="https://d2lxkizvrhu4im.cloudfront.net/images/background_dots_yellow.svg"
                      alt="dots image"
                      className="absolute -left-72 -top-10"
                    />
                    <img
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/right_star_icon.svg"
                      alt="stars image"
                      className="absolute right-0 -top-10"
                    />
                    <img/>
                  </>
                }
               
                <div className={`my-2 ${page === 'tasem-test' ? 'md:my-0' : 'md:my-10'} bg-auto w-8/12 md:w-6/12 md:px-10`}>
                  <img
                    alt="get started"
                    src={map.imgSrc}
                    className="m-0 mx-auto"
                  />
                </div>

                <div className="flex flex-row md:text-left w-full md:w-6/12 md:px-10">
                  <div className="flex flex-col space-y-2 md:space-y-5 ">
                    <div className="flex items-center justify-start">
                      <img
                        alt="get started"
                        src={map.iconSrc}
                        className="m-0 h-12"
                      />
                    </div>
                    <div className="text-2xl md:text-3xl font-semibold w-11/12  md:w-4/5">
                      {t(map.title)}
                    </div>
                    <div className="text-gray-500 text-sm md:text-xl leading-loose">
                      {t(map.description)}
                    </div>
                    {
                      map.button &&
                      <a href={map.button.href} target="_blank" >
                      <div className="text-yellow-500 flex flex-row text-sm md:text-xl font-semibold items-center">
                        <div>{t(map.button.text)}</div>
                        <img
                          src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                          alt="arrow icon"
                          className="transform -rotate-90  w-2.5 translate-x-2 m-0 p-0"
                        />
                      </div>
                      </a>
                    }
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </>
  );
};

export default WorkDone;
