import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Ready from "../ready/ready";
import WorkDone from "../get_started/work_done";
import Work from "../work/work";
import Subscriptions from "../subscriptions";
import Approach from "../human_approach/approach";
import TariffsTable from "../tariffs/tariffs-table";
import SecondaryHeader from "../secondary-header";
import ValuePartners from "../value/value_partners";

import { secondaryMenu } from '../../constants';

import * as classes from "./tasem-test.module.scss";

const Index = () => {
  const {t} = useTranslation();
  const breakpoints = useBreakpoint();
  const analysisData = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/monitor-orange-round.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/worldwide-people.svg",
      title: `${t('a_test_accessible_from_home')}`,
      description: `${t('it_is_now_possible_to_take_a_french')}`,
      button: {
        text: `${t('learn_more')}`,
        href: "/tests/tasem-test/acces"
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/bulb-orange-round.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/grading.svg",
      title: `${t('foolproof_remote_monitoring')}`,
      description: `${t('thanks_to_monitoring_system')}`,
      button: {
        text: `${t('learn_more')}`,
        href: "/intelligence-artificielle"
      }
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/acedmic-cap-orange-round.svg",
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/happy-candidates.svg",
      title: `${t('results_accessible_in_5_days')}`,
      description: `${t('the_score_and_the_digital')}`,
      button: {
        text: `${t('learn_more')}`,
        href: "/tests/tasem-test/score/"
      }
    },
  ];

  const works = [
    {
      title: `${t('understanding_your_score')}`,
      description: `${t('scoring_weighting_calculation')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/person-behind-sheet.svg",
      button: {
        text: `${t('the_scores')}`,
        href: "/tests/tasem-test/score/"
      }
    },
    {
      title: `${t('presentation_of_the_subtests')}`,
      description: `${t('structure_of_the_test')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/person-behind-laptop.svg",
      button: {
        text: `${t('the_subtests')}`,
        href: "/tests/tasem-test/preparation/"
      }
    }
  ];

  const values = [
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/HEC-paris.png",
      program: "Programme Grande École",
      name: "HEC Paris",
      location: "Paris",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/ESCP-business-school.png",
      program: "Programme Grande École",
      name: "ESCP BS",
      location: "Paris, Madrid, Berlin, Londres, Varsovie",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/emlyon-business-school.svg",
      program: "Programme Grande École",
      name: "emlyon BS",
      location: "Lyon, Saint-Etienne, Paris, Shanghai, Mumbai",
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/skema.png",
      program: "Programme Grande École",
      name: "SKEMA BS",
      location: "Paris, Lille Nice, Raleigh, Suzhou, Belo Horizonte, Le Cap",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/audencia.png",
      program: "Programme Grande École",
      name: "Audencia",
      location: "Nantes, Paris",
      
    },
    {
      label: "Master in Management",
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/IMT-business-school.png",
      program: "Programme Grande École",
      name: "Institut Mines Télécom BS",
      location: "Paris",
      
    }
  ];

  const tariffsData = [
    {
        title: "Products",
        list: [
            {title: `${t('digital_skills')}`, Standard: "30 questions", Scale: "40 minutes", Enterprise: "200 points"},
            {title: `${t('subtest_2_calculation')}`, Standard: "15", Scale: "20", Enterprise: "100"},
            {title: `${t('subtest_4_inimum_conditions')}`, Standard: "15", Scale: "20", Enterprise: "100"},
            {title: `${t('verbal_skills')}`, Standard: "30 questions", Scale: "40 minutes", Enterprise: "200 points"},
            {title: `${t('subtest_1_comprehension')}`, Standard: "15", Scale: "20", Enterprise: "100"},
            {title: `${t('subtest_5_xpression')}`, Standard: "15", Scale: "20", Enterprise: "100"},
            {title: `${t('deductive_skills')}`, Standard: "20 questions", Scale: "25 minutes", Enterprise: "200 points"},
            {title: `${t('subtest_3_Reasoning')}`, Standard: "10", Scale: "12,5", Enterprise: "100"},
            {title: `${t('subtest_6_logic')}`, Standard: "10", Scale: "12,5", Enterprise: "100"},
            {title: "TOTAL", Standard: "80 questions", Scale: "105 minutes (1h45)", Enterprise: "600 points"},
        ]
    }
  ];

  const comparisonData = [
    { name: `${t('rate')}`, mereos: '132 €', others: '130 €', icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/tableIconWallet.svg" },
    { name: `${t('geographical_limits')}`, mereos: `${t('no')}`, others: `${t('in_person')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/MapTrifold.svg" },
    { name: 'Limites temporelles', mereos: '24/7', others: `10 dates par ${t('years')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Hourglass.svg" },
    { name: 'Support', mereos: `${t('in_nine_languages')}`, others: `${t('in_french')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Book.svg" },
    { name: `${t('receipt_of_results')}`, mereos: `5 ${t('working_days')}`, others: `5 ${t('working_days')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/BriefcaseMetal.svg" },
    // { name: `${t('time_allocated_per_session')}`, mereos: `30 ${t('seconds')}`, others: `${t('many_hours')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Clock.svg" },
    // { name: `${t('how_we_work')}`, mereos: 'Flexibles', others: `${t('frozen')}`, icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/Lightbulb.svg" }
  ];

  return (
    <div className={`font-sans`}>
      <SecondaryHeader
        menu={secondaryMenu}
      />
      <div
        className={`text-white relative bg-cover lg:bg-contain bg-no-repeat pb-0 space-y-7 ${classes.banner}`}
        style={{
          backgroundImage: `${
            breakpoints.l
              ? `url("https://d2lxkizvrhu4im.cloudfront.net/images/home_top_banner.svg")`
              : `url("https://d2lxkizvrhu4im.cloudfront.net/images/top_banner_backround.svg")`
          }`,
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <div className={`w-full lg:w-7/12 mx-auto md:mx-0 lg:mx-auto space-y-5`}>
            <div className={` text-sm md:text-base font-semibold text-center opacity-50 mt-10`}>
              Le test TASEM©
            </div>
            <div
              className={`md:text-6xl text-center font-bold`}
              style={
                breakpoints.l ? { fontSize: "40px" } : { fontSize: "60px" }
              }
            >
              <h1>{t('tasem_the_French_reference')}</h1>
            </div>
            <div className={`text-center text-sm md:text-xl mx-auto`}>
              {t('the_tasem_test_is_the_benchmark')}
            </div>
          </div>
        </div>
        {/* <img src="images/background_dots.svg" className="lg:block hidden absolute -left-20 h-72 -top-4"/> */}
        <div className=" flex flex-col justify-center items-center ">
          <TariffsTable
            headers={[
              `${t('test')}`,
              `${t('number_of_questions')}`,
              `${t('duration')}`,
              `${t('score_per_event')}`,
            ]}
            data={tariffsData}
          />
        </div>
        {/* <img src="images/background_dots.svg" className="lg:block hidden absolute -right-20 h-80 -bottom-0"/> */}
      </div>

      <div className="lg:my-32">
        <Work works={works}/>
      </div>

      <div className={`mx-auto w-11/12 lg:w-5/6 my-20`}>
        <WorkDone
          title="Passer le TASEM© en ligne"
          data={analysisData}
          page={'tasem-test'}
          titleFull
        />
        <div className=" mx-auto my-20">
          <Ready
            title={t('ready_to_test_mereos')}
            description={t('discover_our_ready_to_use_solutions')}
            leftButton={{
              text: t('register'),
              href: "https://registration.mereos.eu/candidature/?id=1"
            }}
            rightButton={{
              text: t('learn_more'),
              href: ""
            }}
            image="https://d2lxkizvrhu4im.cloudfront.net/images/Market_launch.svg"
          />
          <div className="my-20">
            <ValuePartners
              title={t('partner_schools')}
              subTitle={t('6_higher_education_establishments')}
              values={values}
            />
          </div>
          <div className="my-20">
            <Approach
              title={t('are_you_a_school')}
              description1={t('become_a_mereos_partner')}
              imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/partnership.svg"
              button={{
                text: `${t('learn_more')}`,
                href: "/demo"
              }}
            />
          </div>
          <Subscriptions
            tag={t('COMPARAISON')}
            title={t('tasem_or_tage_mage')}
            description={t("by_taking_the_tasem_test")}
            headers={[
              'TASEM',
              'Tage Mage'
            ]}
            comparisonData={comparisonData}
            button={{
              text: `${t('register')}`,
              href: 'https://registration.mereos.eu/candidature/?id=1'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Index);
