import * as React from "react";

import Layout from "../../../components/layout/layout";
import TesamTest from "../../../components/tasem-test";
import Seo from "../../../components/seo";

const Index = () => {
  return (
    <>
      <Seo
        title="Test TASEM"
        description="Le test TASEM est la référence pour évaluer les aptitudes d’un candidat au suivi d’études en management. Disponible en ligne, il est la voie d’accès idéale aux formations délivrées par les Grandes Écoles françaises. "
      />
      <Layout>
        <TesamTest />  
      </Layout>
    </>
  )
}

export default Index;
